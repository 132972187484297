

var acc = document.getElementsByClassName("accordion");
var i;

for (i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
        for (var j = 0; j < acc.length; j++) {
            if (acc[j].classList.contains("active") && acc[j] !== this) {
                acc[j].classList.toggle("active");
                acc[j].nextElementSibling.style.maxHeight = null;
            }
        }
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.maxHeight) {
            panel.style.maxHeight = null;
        } else {
            panel.style.maxHeight = panel.scrollHeight + "px";
        }
    });
}

$(document).ready(function () {
    window.onscroll = function() {myFunction()};

    var navbar = document.getElementById("sticky");
    var sticky = navbar.offsetTop;
    var parent = navbar.parentElement;
    var child = document.getElementById("sticky-child");
    function myFunction() {
        var childH = child.offsetHeight;
        childH += parseInt(window.getComputedStyle(child).getPropertyValue('margin-top'));
        if (window.pageYOffset >= sticky) {
            navbar.classList.add("sticky")
        } else {
            navbar.classList.remove("sticky");
        }

        if (window.pageYOffset >= (parent.offsetTop + parent.offsetHeight - childH) ) {
            navbar.classList.add("overscrolled")
        } else {
            navbar.classList.remove("overscrolled");
        }
    }

    myFunction();
});
$( document ).ready(function(){
    $( ".summary-toggle" ).click(function(){ // задаем функцию при нажатиии на элемент с классом
        $( ".summary__prices" ).slideToggle(); //  скрываем, или отображаем все элементы <div>
    });
});

$(function() {
    $('select').selectric();
});

$(document).on('click', '.account-btn', function () {
    $('.account-btn').removeClass('chosen');
    $(this).addClass('chosen');
}).on('click', '.payment-btn', function () {
    $('.payment-btn').removeClass('chosen');
    $(this).addClass('chosen');
}).on('click', '.menu-icon', function () {
    $('.menu-icon').toggleClass('toggled');
    $('.body-class').toggleClass('hide-content');
    $('.head-nav__links').toggleClass('opened');
}).on('click', '.summary-toggle', function () {
    $(this).toggleClass('toggled');
});

$(function() {
    $('select').selectric();
});